import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataChangeEvent } from '@x/dashboard/form/components/data-select/data-select.component';
import { IShippingMethodItemObject } from '@x/ecommerce/domain-client';
import {
  ShippingMethodItemCollectionProvider,
  ShippingSlotItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { OrderDialogService } from '../../../orders/services/order-dialog.service';

export interface ShipmentFormDialogData {
  orderId?: number | null;
  methodId?: number | null;
  slotId?: string | null;
  isRequested?: boolean | null;
}

export interface ShipmentFormDialogResult {
  methodId?: number | null;
  slotId?: string | null;
  requestedSlotId?: string | null;
}

@Component({
  selector: 'x-shipment-form-dialog',
  templateUrl: 'shipment-form-dialog.component.html',
  styleUrls: ['shipment-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentFormDialogComponent {
  Providers = {
    ShippingMethodItemCollectionProvider,
    ShippingSlotItemCollectionProvider,
  };

  methodIdControl = new UntypedFormControl(this.data.methodId ?? null);
  slotIdControl = new UntypedFormControl(this.data.slotId ?? null);
  isRequestedControl = new UntypedFormControl(this.data.isRequested ?? true);

  formGroup = new UntypedFormGroup({
    methodId: this.methodIdControl,
    slotId: this.slotIdControl,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: ShipmentFormDialogData,
    private dialogRef: MatDialogRef<ShipmentFormDialogComponent, ShipmentFormDialogResult>,
    private orderDialogs: OrderDialogService,
  ) {}

  submit() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;

    this.dialogRef.close(this.formGroup.value);
  }

  async selectFulfilmentInterval() {
    const shippingMethodId = this.methodIdControl.value;
    const orderId = this.data.orderId;

    if (!orderId) return;

    const result = await this.orderDialogs
      .openFulfilmentIntervalDialog({ orderId, shippingMethodId })
      .afterClosed()
      .toPromise();

    if (!result || !result.interval?.slotAvailability?.slotId) return;

    this.slotIdControl.setValue(result.interval.slotAvailability.slotId);
  }

  onMethodChange(event: DataChangeEvent<IShippingMethodItemObject>) {
    console.log('onMethodChange', event);
    this.slotIdControl.setValue(null);
  }
}
