<h2 mat-dialog-title>Reschedule Shipment</h2>
<form [formGroup]="formGroup" mat-dialog-content>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Shipping Method</mat-label>
        <x-data-select
          formControlName="methodId"
          [provider]="Providers.ShippingMethodItemCollectionProvider"
          (dataChange)="onMethodChange($event)"
          [page]="{ index: 0, size: 100 }"
        ></x-data-select>
      </mat-form-field>
      <mat-form-field *ngIf="methodIdControl.value as methodId">
        <mat-label>Shipping Slot</mat-label>
        <x-data-select
          formControlName="slotId"
          [provider]="Providers.ShippingSlotItemCollectionProvider"
          [filter]="{
            methodId: methodId,
            startAtRange: {
              type: 'relative',
              unit: 'day',
              endOffset: 90,
              expand: true
            }
          }"
          [sort]="{ column: 'startAt', order: 'asc' }"
        ></x-data-select>
        <button type="button" mat-icon-button matSuffix (click)="selectFulfilmentInterval()">
          <fa-icon icon="calendar"></fa-icon>
        </button>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" (click)="submit()">Update</button>
</div>
